import { BodyM, Box, CaptionM, H3 } from '@otovo/rainbow';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage as T } from 'react-intl';

import Spinner from '@otovo/shared/components/LoadingScreens/Spinner';
import RainbowLink from '@otovo/shared/components/RainbowLinkLegacy/RainbowLinkLegacy';
import useHasMounted from '@otovo/shared/hooks/useHasMounted';
import { Cloud$OAuthToken } from '@otovo/shared/types/cloudApi';
import getFirstFromArray from '@otovo/shared/utils/getFirstFromArray';
import { setToken } from '../../../utils/auth/auth';
import LoginError from '../LoginError';
import PageWrapper from '../PageWrapper';
import { getNextPath } from '../getNextPath';
import useProfile from '../hooks/useProfile';
import EnterEmailForm from './EnterEmailForm';
import EnterOtpForm from './EnterOtpForm';
import m from './messages';

const Strong = (...chuncks: Array<ReactNode>) => (
  <strong>{chuncks.join('')}</strong>
);

const GoToOldLoginLink = (...chunks: Array<ReactNode>) => (
  <RainbowLink href="/password/login?utm_source=otp-login&utm_medium=old-login-link">
    {chunks.join('')}
  </RainbowLink>
);

const OtpLoginPage = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const otpRef = useRef<HTMLInputElement[] | null>(null);
  const router = useRouter();
  const { query } = router;
  const [email, setEmail] = useState('');
  const [loginState, setLoginState] = useState('enterEmail');
  const [otpMedium, setOtpMedium] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [, setHasToken] = useState(false);
  const { user, isLoading, error } = useProfile({ redirectToLogin: false });

  const goToOtpInput = (medium: string) => {
    setOtpMedium(medium);
    setLoginState('enterOtp');
  };

  // Avoids an hydration error
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (loginState === 'enterOtp') {
      otpRef.current?.[0]?.focus();
    } else {
      emailRef.current?.focus();
    }
  }, [loginState]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setIsRedirecting(true);

    const nextPath = getNextPath(router);
    router.replace(nextPath);
  }, [user, router]);

  useEffect(() => {
    if (!email && query.email) {
      // Prefill email on initial mount if it's supplied as a query param
      setEmail(getFirstFromArray(query.email));

      // When email and medium is provided as parameters, the user
      // has already been sent from otp code already
      if (query.medium === 'sms' || query.medium === 'email') {
        goToOtpInput(query.medium);
      }
    }
  }, [query.email, query.medium, setEmail, email]);

  function goToEmailInput() {
    setLoginState('enterEmail');
  }

  function handleOnTokenReceived(token: Cloud$OAuthToken) {
    // Store the token to local storage
    setToken(token);
    // Re-render the component to start downloading the user profile (via useProfile)
    setHasToken(true);
  }
  if (isLoading || isRedirecting || !hasMounted) {
    // We want to avoid the flash of the loginScreen if we're in the middle of a
    // redirect dance between mypage routes.
    return (
      <PageWrapper>
        <Box textAlign="center">
          <Spinner />
        </Box>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <LoginError />
      </PageWrapper>
    );
  }

  if (loginState === 'enterOtp') {
    const SendAgainLink = (...chunks: Array<ReactNode>) => {
      return (
        <RainbowLink as="button" onClick={goToEmailInput}>
          {chunks.join('')}
        </RainbowLink>
      );
    };

    return (
      <PageWrapper>
        <Box mt="3" backgroundColor="white" mx="_6" p="6" borderRadius="3">
          <CaptionM mb="0" display={{ s: 'none', m: 'block' }}>
            <T {...m.myPageTag} />
          </CaptionM>

          <H3 mb="3">
            <T {...m.enterOtpHeading} />
          </H3>

          <BodyM mb="5" fontWeight={[500, 400]}>
            <T
              {...m.enterOtpInstructions}
              values={{
                medium: otpMedium,
                strong: Strong,
                link: SendAgainLink,
              }}
            />
          </BodyM>
        </Box>
        <EnterOtpForm
          email={email}
          inputRef={otpRef}
          onTokenReceived={handleOnTokenReceived}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Box mt="3" backgroundColor="white" mx="_6" p="6" borderRadius="3">
        <CaptionM display={{ s: 'none', m: 'block' }}>
          <T {...m.myPageTag} />
        </CaptionM>
        <H3 mb="3">
          <T {...m.signInToAccountHeading} />
        </H3>

        <BodyM mb="5" fontWeight={[500, 400]}>
          <T {...m.signInInstructions} />
        </BodyM>
        <EnterEmailForm
          inputRef={emailRef}
          email={email}
          setEmail={setEmail}
          onNext={goToOtpInput}
        />
        <Box mt="3">
          <T
            {...m.useOldLogin}
            values={{
              link: GoToOldLoginLink,
            }}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default OtpLoginPage;
