import { Box } from '@otovo/rainbow';
import Footer from '@otovo/shared/Footer';
import ErrorBoundary from '@otovo/shared/components/ErrorBoundary/ErrorBoundary';
import dynamic from 'next/dynamic';

import use100vh from '@otovo/shared/hooks/use100vh';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useProfile from './LoginPage/hooks/useProfile';

const MyPageNav = dynamic(() => import('./MyPageNav'));

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
  footer?: React.ReactNode;
  navbar?: React.ReactNode;
};

const MyPagePage = (props: Props) => {
  const { children, footer = <Footer />, navbar, ...rest } = props;
  const [showNavigationEntries, setShowNavigationEntries] = useState(false);

  const router = useRouter();

  const { user } = useProfile({ redirectToLogin: false });
  useEffect(() => {
    const { asPath } = router;
    const hasLocaleInUrl = router.locales.some((locale) =>
      window.location.pathname.startsWith(`/${locale}`),
    );

    // Showing the menu and the footer will be confusing unless we know the
    // locale, since we would use the default locale. Therefore, we hide them,
    // and redirects the user to to the correct locale as soon as we know their
    // locale.
    setShowNavigationEntries(hasLocaleInUrl);

    if (user && !hasLocaleInUrl) {
      router.replace(`/${user.profile.locale}${asPath}`);
    }
  }, [router, user]);

  return (
    <>
      <link
        rel="stylesheet"
        href="https://otovoweb.s3.eu-central-1.amazonaws.com/storefront-global-styles-workaround.css"
      />
      <div style={{ height: use100vh() }} className="relative flex flex-col">
        {showNavigationEntries && (navbar || <MyPageNav />)}
        <ErrorBoundary name="Top level ErrorBoundary">
          <Box {...rest} as="main">
            {children}
          </Box>
        </ErrorBoundary>
        {showNavigationEntries && footer}
      </div>
    </>
  );
};

export default MyPagePage;
