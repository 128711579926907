import getFirstFromArray from '@otovo/shared/utils/getFirstFromArray';
import { NextRouter } from 'next/router';

export function getNextPath(router: NextRouter) {
  const { next } = router.query;

  if (next) {
    const nextPath = getFirstFromArray(next);
    if (nextPath) {
      return nextPath;
    }
  }

  return `/dashboard`;
}
